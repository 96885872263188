<template>
  <b-container fluid>
    <bo-page-title />

    <b-card v-if="isList" no-body>
      <b-card-header>
        <b-row>
          <b-col lg="6">
            <h5 class="card-title">Daftar {{ pageTitle }}</h5>
          </b-col>
          <b-col lg="3">
          </b-col>
          <b-col lg="3">
            <SearchInput :value.sync="filter.search" @search="doFill" />
          </b-col>
        </b-row>

      </b-card-header>
      <b-card-body class="p-0">
        <b-table
          :fields="fields"
          :items="dataList||[]"
          :per-page="perPage"
          :primary-key="idKey"
          :busy="!dataList"
          responsive
          show-empty
          striped
        >
          <template #empty>
            <div class="text-center">
              <h4 align="center"><span v-if="filter.status || filter.search">Hasil pencarian tidak ditemukan</span>
              <span v-else>Belum ada data {{ pageTitle }} yang terdaftar</span></h4>
            </div>
          </template>
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>
          <template #cell(number)="v">
            {{(data.per_page*(data.current_page-1))+v.index+1}}
          </template>
          <template
            #cell(action)="data"
          >
            <div class="list-icons">
              <router-link class="list-icons-item"
                v-if="moduleRole('edit')"
                :to="{name: $route.name, params: {pageSlug: data.item[idKey]}}"
                data-toggle="tooltip" data-placement="top" title="View"><i
                  class="icon-pencil5"></i></router-link>
            </div>
            <span v-if="!(moduleRole('delete') || moduleRole('changeStatus') || moduleRole('edit'))">-</span>
          </template>
        </b-table>
      </b-card-body>
      <b-card-footer class="pagination-custom" v-if="(dataList||[]).length&&data.total>10">        
        <b-pagination
          class="mb-0"
          v-model="pageNo"
          :per-page="data.per_page"
          :total-rows="data.total"
        />
      </b-card-footer>
    </b-card>
    <template v-else>
      <Form :row.sync="row" v-bind="passToSub" />
    </template>
  </b-container>
</template>

<script>
import GlobalVue from '@/libs/Global.vue'
import Form from './Form.vue'
const _ = global._

export default {
  extends: GlobalVue,
  components:{Form},
  data() {
    return {
      idKey:'met_id',
      statusKey:'met_is_active',
      fields: [
        {
          key: 'number',
          label: '#',
        },
        {
          key: 'met_subject',
          label: 'Subjek',
        },
        {
          key: 'action',
          label: 'Aksi',
        },
      ],
    }
  },
  computed:{
    passToSub(){
      return _.assign(this.passToSubComp||{})
    }
  },
  mounted(){
    this.apiGet()
    if(this.$route.params.pageSlug){
      this.validateModuleRoleCrud()
    }
  },
  methods: {
    doFill(){
      this.doFilter()
    }
  },
  watch:{
    $route(){
      this.apiGet()
    },
    '$route.params.pageSlug':function(){
      this.$set(this,'filter',{})
    }
  }
}
</script>